export default {
  1: {
    name: "Incubator",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/14c1f20a-6258-4b58-f820-f42820ebab00/original",
    description:
      "By merging the DNA of two bulls from the same guild within this highly advanced incubator the Bulls were able to create a magnificent and unique Teen Bull. These Teen bulls are unpredictable, to say the least.",
  },
  2: {
    name: "Merger Orb",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/403b6775-bd13-47b2-d01d-054fef68d400/original",
    description:
      "A brave teen had to willingly give up his physical form to become this Merger Orb. These Orbs can be used by four Teens of different guilds to summon a God Bull.",
  },
  3: {
    name: "Forge Block",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/194bb4b0-4a73-4ce4-be7f-e58108e7fd00/original",
    description:
      "The Forge Block is a utility required throughout the next phase of B.A.P. gamified tokenomics. A Forge Block is a required ingredient in a Formula. Some Formulas might have more than one Forge Block requirement, which might be based on the Formula level. This will be true for both standalone Formulas as well as Formulas within the God Ape Campaign (GAC).",
  },
  4: {
    name: "Element Incubator",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/7d278f5b-1321-4249-688e-041f00f80200/original",
    description:
      "Created by merging the DNA of two bulls from the same guild, this highly advanced incubator enables the Apes to call down the magnificent and powerful God Element. These mystical God Elements are unpredictable, to say the least.",
  },
  5: {
    name: "Creator Passport",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/be06c88b-c698-451a-2157-c30f90c44300/original",
    description:
      "One of the holders of this passport has an OG Bull that is secretly the Creator in disguise. When the time comes, the Creator shall reveal himself",
  },
  6: {
    name: "Methane Crystals",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/237c4044-2c9b-4750-e706-996bd2217b00/original",
    description: "Needed to summon a new Ape",
  },
  7: {
    name: "Mini Crystals",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/d071c44f-7b10-483f-cf9d-19c523a7aa00/original",
    description: "Needed to summon a new Teen",
  },
  20: {
    name: "Meth Machine",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/145f89ef-bad4-4149-200c-14f4da92d500/original",
    description:
      "The METH Machine is guild specific and enables Teen Bulls of the same guild, held in the same wallet, to earn 5 methane tokens per day (every 24hrs) for each Teen Bull. You must harvest the Teen Bull generated METH to the ‘METH bank’ daily as these tokens do not compound.",
    guild: "North",
  },
  21: {
    name: "Meth Machine",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/145f89ef-bad4-4149-200c-14f4da92d500/original",
    description:
      "The METH Machine is guild specific and enables Teen Bulls of the same guild, held in the same wallet, to earn 5 methane tokens per day (every 24hrs) for each Teen Bull. You must harvest the Teen Bull generated METH to the ‘METH bank’ daily as these tokens do not compound.",
    guild: "South",
  },
  22: {
    name: "Meth Machine",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/145f89ef-bad4-4149-200c-14f4da92d500/original",
    description:
      "The METH Machine is guild specific and enables Teen Bulls of the same guild, held in the same wallet, to earn 5 methane tokens per day (every 24hrs) for each Teen Bull. You must harvest the Teen Bull generated METH to the ‘METH bank’ daily as these tokens do not compound.",
    guild: "East",
  },
  23: {
    name: "Meth Machine",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/145f89ef-bad4-4149-200c-14f4da92d500/original",
    description:
      "The METH Machine is guild specific and enables Teen Bulls of the same guild, held in the same wallet, to earn 5 methane tokens per day (every 24hrs) for each Teen Bull. You must harvest the Teen Bull generated METH to the ‘METH bank’ daily as these tokens do not compound.",
    guild: "West",
  },
  30: {
    name: "Teen Resurrection",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/0d4c19a8-4e5b-4775-3b0c-5b4686b61800/original",
    description:
      "Using this power, you can resurrect a sacrificed Teen from the Underworld. The resurrected Teen must belong to the same guild as the God Bull Power. In order to maintain balance in the BAP universe, you must exchange a teen of the same guild as the resurrected teen. Each newly resurrected Teen generates 5 METH tokens per day and this METH compounds daily; the same as the OG Bulls.",
    guild: "North",
  },
  31: {
    name: "Teen Resurrection",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/0d4c19a8-4e5b-4775-3b0c-5b4686b61800/original",
    description:
      "Using this power, you can resurrect a sacrificed Teen from the Underworld. The resurrected Teen must belong to the same guild as the God Bull Power. In order to maintain balance in the BAP universe, you must exchange a teen of the same guild as the resurrected teen. Each newly resurrected Teen generates 5 METH tokens per day and this METH compounds daily; the same as the OG Bulls.",
    guild: "South",
  },
  32: {
    name: "Teen Resurrection",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/0d4c19a8-4e5b-4775-3b0c-5b4686b61800/original",
    description:
      "Using this power, you can resurrect a sacrificed Teen from the Underworld. The resurrected Teen must belong to the same guild as the God Bull Power. In order to maintain balance in the BAP universe, you must exchange a teen of the same guild as the resurrected teen. Each newly resurrected Teen generates 5 METH tokens per day and this METH compounds daily; the same as the OG Bulls.",
    guild: "East",
  },
  33: {
    name: "Teen Resurrection",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/0d4c19a8-4e5b-4775-3b0c-5b4686b61800/original",
    description:
      "Using this power, you can resurrect a sacrificed Teen from the Underworld. The resurrected Teen must belong to the same guild as the God Bull Power. In order to maintain balance in the BAP universe, you must exchange a teen of the same guild as the resurrected teen. Each newly resurrected Teen generates 5 METH tokens per day and this METH compounds daily; the same as the OG Bulls.",
    guild: "West",
  },
  40: {
    name: "Replenishment",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/00e729d3-59ce-4dd8-66d4-4de5b598d800/original",
    description:
      "Using this Breed Replenishment power, you can replenish an OG bull with two, one, or zero breeds to a bull with all three breeds. This God Bull Power is guild specific and will only work on OG Bulls of the same guild.",
    guild: "North",
  },
  41: {
    name: "Replenishment",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/00e729d3-59ce-4dd8-66d4-4de5b598d800/original",
    description:
      "Using this Breed Replenishment power, you can replenish an OG bull with two, one, or zero breeds to a bull with all three breeds. This God Bull Power is guild specific and will only work on OG Bulls of the same guild.",
    guild: "South",
  },
  42: {
    name: "Replenishment",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/00e729d3-59ce-4dd8-66d4-4de5b598d800/original",
    description:
      "Using this Breed Replenishment power, you can replenish an OG bull with two, one, or zero breeds to a bull with all three breeds. This God Bull Power is guild specific and will only work on OG Bulls of the same guild.",
    guild: "East",
  },
  43: {
    name: "Replenishment",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/00e729d3-59ce-4dd8-66d4-4de5b598d800/original",
    description:
      "Using this Breed Replenishment power, you can replenish an OG bull with two, one, or zero breeds to a bull with all three breeds. This God Bull Power is guild specific and will only work on OG Bulls of the same guild.",
    guild: "West",
  },
  1109: {
    name: "God Life Force",
    image:
      "https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/e1ad3c6c-fd08-4e4d-0018-2a5e25d10600/original",
  },
};
